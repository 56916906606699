<template>
  <v-app-bar elevation=1 class="hide-on-print" style="background-color: rgba(255, 255, 255, .9);">
    <img :src="require('@/assets/images/logos/LP/dark blue.svg')" class="h-100 ma-3 pointer" @click="goToHome()">

    <template v-if="!isCompact">
      <v-tabs v-model="tab" class="w-100">
        <v-tab class="d-none" />
        <template v-for="item of menu">
          <v-spacer v-if="item === 'divider'" />
          <v-tab v-else :href="onHomeRoute ? item.path : ''" @click="item.atClick">
            <div class="d-flex flex-column align-center py-2">
              <img v-if="item.img" height="21" :src="item.img" />
              <v-icon v-else size=large>{{ item.icon }}</v-icon>
              {{ item.title }}
            </div>
          </v-tab>
        </template>
      </v-tabs>
    </template>

    <template v-else>
      <v-spacer />
      <v-btn color=primary>
        <v-icon size=xx-large>mdi-menu</v-icon>
        <v-menu activator=parent>
          <v-list min-width=80vw>
            <template v-for="item of menu">
              <CustomDivider v-if="item === 'divider'" class="my-3"/>
              <v-list-item v-else :href="onHomeRoute ? item.path : ''" @click="item.atClick">
                <v-list-item-title class="font-weight-100 d-flex align-center gap-3">
                  <img v-if="item.img" height=28 width=28 cover :src="item.img" />
                  <v-icon v-else size=large>{{ item.icon }}</v-icon>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-btn>
    </template>

  </v-app-bar>
</template>

<script>
import CustomDivider from '@/components/CustomDivider.vue'

export default {
  components: { CustomDivider },
  data() {
    return {
      tab: null
    }
  },
  mounted() {
    this.tab = null
  },
  methods: {
    goToHome(section) {
      const scrollTo = () => {
        if (section)
          document.getElementById(section).scrollIntoView()
        else {
          this.tab = null
          window.scrollTo(0, 0)
        }
      }

      if (this.$route.name === 'home') {
        scrollTo()
      } else {
        this.$router.push('/').then(() => {
          setTimeout(() => {
            scrollTo()
          }, 200)
        })
      }
    }
  },
  computed: {
    isCompact() {
      return this.$vuetify.display.smAndDown
    },
    onHomeRoute() {
      return this.$route.name === 'home'
    },

    menu() {
      return [
        { title: 'Qui sommes-nous',
          img: require('@/assets/images/logos/LP/black.svg'),
          href: '/#about-us',
          atClick: () => this.goToHome('about-us')
        },
        { title: 'Nos Outils',
          icon: 'mdi-application-edit-outline',
          href: '/#tools',
          atClick: () => this.goToHome('tools')
        },
        { title: 'Nos Formations',
          icon: 'mdi-account-school-outline',
          href: '/#formations',
          atClick: () => this.goToHome('formations')
        },
        'divider',
        { title: 'Nous contacter',
          icon: 'mdi-email-fast-outline',
          href: '/#contact',
          atClick: () => this.goToHome('contact')
        }
      ]
    }
  }
}
</script>