const firebaseConfig = {
    apiKey: "AIzaSyCbDOOQN4uRo7BRsqT5x4xdgtMIDJZ-2_w",
    authDomain: "lp-site-a4fe8.firebaseapp.com",
    projectId: "lp-site-a4fe8",
    storageBucket: "lp-site-a4fe8.appspot.com",
    messagingSenderId: "104962166186",
    appId: "1:104962166186:web:96ac87f2d899e35e6d2fd3",
    measurementId: "G-HR1ZN400BB"
}

export default firebaseConfig